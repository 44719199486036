<template>
  <!--BEGIN parent container-->
  <v-container fluid>
    <!--BEGIN the card-->
    <v-card elevation="0">
      <v-card-title class="overline">
        <!--BEGIN back button action-->
        <v-btn
          text
          @click="$router.back()"
          color="error"
          class="mr-3 text-capitalize"
        >
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <!--END back button action-->
        <v-icon class="mx-2">mdi-file-document-edit-outline</v-icon>
        <span class="font-weight-bold text-uppercase text-lg-h5"
          >Requisition</span
        >
        <v-spacer />
        <RequestCardActions
          :activateActionButton="activateActionButton"
          :hideActionButton="hideActionButton"
          :imprestActionsPosition="imprestActionsPosition"
          :requisition="requisition"
          :saveButtonCaption="saveButtonCaption"
          :displayBudget="displayBudget"
          @resolveRequisitionStatus="resolveRequisitionStatus"
          @saveRequisition="saveRequisition"
          @sendApprovalRequest="sendApprovalRequest"
          @deleteRequisition="deleteRequisition"
          @cancelRequisition="cancelRequisition"
          @reOpenRequisition="reOpenRequisition"
          @CheckBudget="CheckBudget"
          v-if="imprestActionsPosition === 'top'"
        />
      </v-card-title>
      <!-- END the card title-->
      <!--BEGIN the card form body-->
      <v-divider></v-divider>
      <v-row class="flex-nowrap">
        <v-col cols="12" md="12" sm="6">
          <v-card flat class="pa-3">
            <v-card-text class="mb-n6">
              <div v-if="isView" class="mt-n4 mb-3 d-flex justify-end">
                <v-chip
                  v-if="routeParams"
                  class="ma-2"
                  :color="getStatusColor(requisition.status).color"
                  text-color="white"
                >
                  {{ requisition.status }}
                </v-chip>
              </div>
              <v-form
                ref="requisitionForm"
                class="requisition-form"
                v-model="isValid"
                lazy-validation
                :rules="rules"
                :persist="false"
                :validate-on-blur="false"
                :validate-on-change="false"
              >
                <v-row>
                  <!--BEGIN requisition document type-->
                  <v-col
                    cols="12"
                    sm="6"
                    :md="isMultiCurrencyEnabled ? '3' : '4'"
                    class="mb-n3"
                    v-if="!isView"
                  >
                    <v-text-field
                      :value="documentType"
                      label="Document Type"
                      readonly
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <!--END requisition document type-->
                  <!--BEGIN requisition document type-->
                  <v-col
                    cols="12"
                    sm="6"
                    :md="isMultiCurrencyEnabled ? '3' : '4'"
                    class="mb-n3"
                    v-if="isView"
                  >
                    <v-text-field
                      v-model="requisition.documentType"
                      label="Document Type"
                      readonly
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <!--END requisition document type-->
                  <!--BEGIN requisition due date-->
                  <v-col
                    cols="12"
                    sm="6"
                    :md="isMultiCurrencyEnabled ? '3' : '4'"
                    class="mb-n3"
                    v-if="showReqDueDate"
                  >
                    <v-menu
                      v-model="formData.dueDateCalendarButtonIcon"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      :disabled="readOnlyCheck"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData.dueDate"
                          label="Due date"
                          prepend-inner-icon="mdi-calendar"
                          color="primary"
                          dense
                          :rules="[rules.date]"
                          :disabled="readOnlyCheck"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.dueDate"
                        @input="formData.dueDateCalendarButtonIcon = false"
                        :disabled="readOnlyCheck"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!--END requisition due date-->
                  <!--BEGIN requisition user requested for-->
                  <v-col
                    cols="12"
                    sm="6"
                    :md="isMultiCurrencyEnabled ? '3' : '4'"
                    class="mb-n3"
                    v-if="!isStoreRequest"
                  >
                    <v-autocomplete
                      :items="users"
                      :item-text="
                        (item) =>
                          userData.is_navuser
                            ? item.full_name
                            : item.number.concat(
                                ': ',
                                item.first_name,
                                ' ',
                                item.last_name
                              )
                      "
                      :item-value="
                        (item) =>
                          userData.is_navuser ? item.userId : item.number
                      "
                      dense
                      v-model="formData.requestedFor"
                      :readOnly="readOnlyCheck"
                      required
                      :rules="[rules.requestedFor]"
                      outlined
                      placeholder="Requested For"
                      persistent-placeholder
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:label>
                        <span class="font-weight-bold">Assigned Purchaser</span>
                        <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--END requisition user requested for-->
                  <!--BEGIN currency picker -->
                  <v-col
                    cols="12"
                    md="3"
                    v-if="isMultiCurrencyEnabled && !isStoreRequest"
                    sm="12"
                    class="mb-n3"
                  >
                    <v-autocomplete
                      :items="currencies"
                      :item-text="(item) => `${item.symbol}${item.displayName}`"
                      :item-value="(item) => item.code"
                      v-model="formData.currencyCode"
                      dense
                      label="Select Currency"
                      outlined
                      :readOnly="readOnlyCheck"
                      persistent-placeholder
                    />
                  </v-col>
                  <!--END currency picker -->
                  <!--BEGIN requisition vendors name-->
                  <v-col
                    v-if="isPurchaseRequest && showReqVendor"
                    cols="12"
                    sm="6"
                    md="4"
                    class="mb-n2"
                  >
                    <v-autocomplete
                      label="Vendor Name/Optional"
                      :items="vendors"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item.no"
                      v-model="formData.buyFromVendorNo"
                      dense
                      :readOnly="readOnlyCheck"
                      required
                      outlined
                      persistent-placeholder
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-autocomplete>
                  </v-col>
                  <!--END requisition vendor name-->
                  <!--BEGIN requisition vendor Number-->
                  <v-col
                    v-if="isPurchaseRequest && showReqVendor"
                    cols="12"
                    sm="6"
                    md="4"
                    class="mb-n2"
                  >
                    <v-text-field
                      label="Vendor Number"
                      dense
                      disabled
                      :value="formData.buyFromVendorNo"
                      required
                      :readOnly="readOnlyCheck"
                      outlined
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <!--END requisition vendor Number-->
                  <!--BEGIN requisition item category -->
                  <v-col
                    v-if="isPurchaseRequest && showReqCategory"
                    cols="12"
                    sm="6"
                    md="4"
                    class="mb-n2"
                  >
                    <v-autocomplete
                      label="Item Category"
                      :items="categories"
                      :item-text="(item) => item.Description"
                      :item-value="(item) => item.Code"
                      v-model="formData.Item_Category_Code"
                      dense
                      :readOnly="readOnlyCheck"
                      required
                      outlined
                      persistent-placeholder
                      :menu-props="{ bottom: true, offsetY: true }"
                    ></v-autocomplete>
                  </v-col>
                  <!-- END requisition item category -->
                  <!--BEGIN requisition store location-->
                  <v-col
                    v-if="
                      requisitionType.isStoreRequisition ||
                      resolveRequisitionType(requisitionType.storeRequisition)
                    "
                    cols="12"
                    sm="6"
                    md="12"
                    class="mb-n3"
                  >
                    <v-autocomplete
                      :items="locations"
                      :item-text="(item) => item.name"
                      :item-value="(item) => item.code"
                      v-model="formData.locationCode"
                      dense
                      :readOnly="readOnlyCheck"
                      required
                      :rules="[rules.location]"
                      outlined
                    >
                      <template v-slot:label>
                        <span class="font-weight-bold">Location</span>
                        <span class="red--text">*</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--END requisition store location-->
                  <!--BEGIN requisition items description-->
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="mb-n3"
                    v-if="displayBudget"
                  >
                    <v-textarea
                      v-model="formData.description"
                      rows="2"
                      placeholder="Type here..."
                      :rules="rule.description"
                      required
                      clearable
                      counter
                      :readOnly="readOnlyCheck"
                      outlined
                    >
                      <template v-slot:label>
                        <span class="font-weight-bold">Description</span>
                        <span class="red--text">*</span>
                      </template>
                    </v-textarea>
                  </v-col>
                  <!--END requisition items description-->
                  <!--BEGIN dimension panel-->
                  <v-col cols="12" md="12" sm="12" class="mt-n3 mb-3">
                    <v-expansion-panels v-model="panel" focusable>
                      <v-expansion-panel>
                        <!--BEGIN dimensions-->
                        <v-expansion-panel-header>
                          <span class="table-font"
                            >Click to view &amp; add available dimensions</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <RequisitionDimension
                            :dimensionValues="dimensionValues"
                            :formData="formData"
                            :readOnlyCheck="readOnlyCheck"
                            :userData="userData"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <!--END dimension panel-->
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                    class="mb-n16"
                    v-if="userData.is_navuser"
                  >
                    <v-text-field
                      v-model="formData.link"
                      label="Link"
                      :readOnly="readOnlyCheck"
                      outlined
                      persistent-placeholder
                      placeholder="Enter Link ... in the format E.G C::\Users\Documents\file.pdf"
                    ></v-text-field>
                  </v-col>
                  <!--BEGIN requisition items attachments-->
                  <v-col cols="12" sm="6" md="12" class="mb-n16" v-else>
                    <div>
                      <v-file-input
                        accept="image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        v-model="attachmentBlobs"
                        prepend-icon=""
                        append-icon="mdi-paperclip"
                        outlined
                        :disabled="readOnlyCheck"
                        counter
                        :show-size="1000"
                        chips
                        :rules="rule.attachment"
                        :clearable="true"
                        label="Upload Attachment"
                        ref="uploadAttachment"
                        multiple
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label>
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </div>
                    <!--BEGIN file attachments view-->
                    <div class="mt-n5" v-if="attachmentBase64.length !== 0">
                      <v-icon small>mdi-paperclip</v-icon>
                      <span class="font-weight-regular"
                        >Preview File Attachments</span
                      >
                      <br />
                      <div class="d-flex flex-row">
                        <div v-for="(item, i) in attachmentBase64" :key="i">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                dark
                                color="green lighten-1"
                                class="ma-2"
                                @click="openDialog(item.attachment)"
                                v-bind="attrs"
                                v-on="on"
                                close
                                @click:close="removeBase64(item.fileName)"
                              >
                                <span class="table-font text-truncate">{{
                                  item.fileName || "My Attachment"
                                }}</span>
                              </v-chip>
                            </template>
                            <span>click to open</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                    <!--END file attachments view-->
                  </v-col>
                  <!--END requisition items attachments-->
                </v-row>
              </v-form>
              <!--END requisition form-->
            </v-card-text>
          </v-card>
        </v-col>
        <!--BEGIN vertical line to border the two columns-->
        <!--END vertical line to border the two columns-->
      </v-row>
      <v-row class="px-7">
        <v-col cols="12" md="12">
          <!--BEGIN the billing items table-->
          <BillingItems
            :formData="formData"
            :billingItems="billingItems"
            :settings="settings"
            :documentType="documentType"
            :isView="isView"
            :requisition="requisition"
            :locations="locations"
            :dimensionValues="dimensionValues"
            :readOnlyCheck="readOnlyCheck"
            :currencyCode="
              isMultiCurrencyEnabled ? formData.currencyCode : undefined
            "
          />
          <!--END the billing items table-->
          <v-col cols="12" md="12">
            <ApprovalEntries
              v-if="formData.status !== 'Open' && formData.status"
              :approvalEntries="requisition.approvalEntries"
            />
          </v-col>
          <v-divider v-if="imprestActionsPosition === 'bottom'" />
          <RequestCardActions
            :activateActionButton="activateActionButton"
            :hideActionButton="hideActionButton"
            :imprestActionsPosition="imprestActionsPosition"
            :requisition="requisition"
            :saveButtonCaption="saveButtonCaption"
            @resolveRequisitionStatus="resolveRequisitionStatus"
            @saveRequisition="saveRequisition"
            @sendApprovalRequest="sendApprovalRequest"
            @deleteRequisition="deleteRequisition"
            @cancelRequisition="cancelRequisition"
            @CheckBudget="CheckBudget"
            @reOpenRequisition="reOpenRequisition"
            v-if="imprestActionsPosition === 'bottom'"
          />
        </v-col>
      </v-row>
    </v-card>
    <!--END the card-->
    <!--BEGIN requisition image preview component-->
    <req-image-preview
      :base64="base64"
      :dialog="imgDialog"
      v-on:close-dialog="closeDialog"
      :routeParams="routeParams"
      :isView="isView"
      :documentType="documentType"
    />
    <!--END requisition image preview component-->
  </v-container>
  <!--END parent container-->
</template>
<script>
import RequisitionDimension from "./RequisitionDimension.vue";
import ApprovalEntries from "./approvalEntries.vue";
import BillingItems from "./ReqBillingItemsTable.vue";
// import RequisitionCardSummary from './RequisitionCardSummary.vue'
import ReqImagePreview from "./ReqImagePreview.vue";
import ReqMixin from "../ReqMixin";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import RequestCardActions from "./RequestCardActions.vue";
export default {
  name: "RequisitionCard",
  mixins: [ReqMixin],
  components: {
    BillingItems,
    RequisitionDimension,
    ReqImagePreview,
    ApprovalEntries,
    RequestCardActions,
  },
  data: () => ({
    isValid: true,
    isView: false,
    imgDialog: false,
    panel: [0],
    base64: "",
    attachmentBlobs: [],
    attachmentBase64: [],
    requisitionType: {
      purchaseRequisition: "Purchase Requisition",
      userRequisition: "User Requisition",
      storeRequisition: "Store Requisition",
      isPurchaseRequisition: false,
      isUserRequisition: false,
      isStoreRequisition: false,
    },
    formData: {
      documentType: "",
      no: "",
      description: "",
      dueDate: "",
      requestedFor: "",
      buyFromVendorName: "",
      headerNo: "",
      buyFromVendorNo: "",
      requisitionLines: [],
      locationCode: "",
      attachments: [],
      fundNo: "",
      Item_Category_Code: "",
      dimensionSpeedkeyCode: "",
      globalDimension1Code: "",
      globalDimension2Code: "",
      globalDimension3Code: "",
      globalDimension4Code: "",
      globalDimension5Code: "",
      globalDimension6Code: "",
      globalDimension7Code: "",
      globalDimension8Code: "",
      currencyCode: "",
      link: "",
    },
    rules: {
      date: (value) => !!value || "Due date is required!",
      requestedFor: (value) => !!value || "User is required!",
      vendorName: (value) => !!value || "Vendor name is required!",
      vendorNumber: (value) => !!value || "Vendor no. required!",
      location: (value) => !!value || "Location Is required!",
      required: (value) => !!value || "Is required!",
    },
  }),
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Requisition/getVendors");
      v.$store.dispatch("Requisition/getDimensionValues");
      v.$store.dispatch("Requisition/getLocations");
      v.$store.dispatch("Requisition/getBillingItems");
      v.$store.dispatch("Requisition/getUnitsOfMeasure");
      v.$store.dispatch("Dashboard/setMiniDrawer", true);
      if (!v.userData.is_navuser) {
        v.$store.dispatch("imprest/getdimensionSpeedKey");
        v.$store.dispatch("imprest/getfunds");
        v.$store.dispatch("imprest/getCurrencies");
      } else {
        v.$store.dispatch("Inventory/getCategories");
      }
      if (to.query.code) {
        v.isView = true;
        v.$store.dispatch("Requisition/showRequisition", v.$route.query.code);
      }
    });
  },
  mounted() {
    this.formData.requestedFor = this.userData.employee;
    this.checkRequisitionType(this.documentType, this.requisitionType);
    if (!this.isView) {
      this.formData.requestedFor = this.employee.number;
    }
    Event.$on("reloadAfterSave", () => {
      this.$router.go();
    });
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    categories: function () {
      return this.$store.getters["Inventory/inventoryGetters"]("categories");
    },
    rule() {
      return {
        attachment: [
          (value) => !!value || "Attachment is required!",
          (value) =>
            !value ||
            !value.some((v) => v.size > 5000000) ||
            "Each file size should be less than 5 MB!",
          (value) => !value || !value.some((v) => v.size > 5000000) || false,
        ],
        description: [
          (value) => !!value || "Description is required!",
          (value) =>
            (value && value.length) < 150 ||
            "Less than 150 characters required!",
        ],
      };
    },
    /**
     * @description - get the requisition
     * @returns {*}
     */
    requisition() {
      return this.$store.getters["Requisition/reqGetters"]("requisition");
    },
    /**
     * Get currently authenticated employee
     * @returns {any}
     */
    employee() {
      return this.$store.getters["auth/user"].profile;
    },
    /**
     * Get the list of vendors
     * @returns {any}
     */
    vendors() {
      return this.$store.getters["Requisition/reqGetters"]("vendors");
    },
    /**
     * Get the list of users
     * @returns {any}
     */
    users() {
      return this.employees;
    },
    /**
     * Get the list of locations
     * @returns {any}
     */
    locations() {
      return this.$store.getters["Requisition/reqGetters"]("locations");
    },
    currencies() {
      return this.$store.getters["imprest/imprestGetters"]("currencies");
    },
    settings() {
      let settings = this.$store.getters["Admin/adminGetters"]("settings");
      return Object.keys(settings).length > 0
        ? JSON.parse(settings.cashmgt_settings)
        : null;
    },
    displayBudget() {
      return this.settings ? this.settings.show_budget_balances : null;
    },
    isMultiCurrencyEnabled() {
      return this.settings ? this.settings.multiple_currency : false;
    },
    saveButtonCaption() {
      return this.settings ? this.settings.cashmgt_save_caption : "Save";
    },
    imprestActionsPosition() {
      return this.settings ? this.settings.cashmgt_actions_position : "top";
    },
    showReqVendor() {
      return this.settings ? this.settings.showReqVendor : true;
    },
    showReqDueDate() {
      return this.settings ? this.settings.showReqDueDate : true;
    },
    showReqCategory() {
      return this.settings ? this.settings.showReqCategory : true;
    },
    /**
     * Get the decoded document type otherwise return code
     * @returns {string}
     */
    documentType() {
      if (this.$route) {
        if (this.$route.params.code) {
          if (!helpers.isBase64(this.$route.params.code)) {
            return this.formData.documentType;
          }
          return atob(this.$route.params.code);
        }
      }
      return this.formData.documentType;
    },
    /**
     * Get the list of billing items
     * @returns {any}
     */
    billingItems() {
      return this.$store.getters["Requisition/reqGetters"](
        "billingItems"
      ).filter((el) => {
        if (this.requisitionType.isStoreRequisition) {
          return el.type.toLowerCase() === "item";
        } else if (el.Item_Category_Code) {
          return el.Item_Category_Code.length > 0;
        }
        {
          return el;
        }
      });
    },
    /**
     * Check the status to disable or enable the input field
     * @returns {boolean}
     */
    readOnlyCheck() {
      return this.isView && this.requisition.status !== "Open";
    },
    /**
     * If the page is view, hide submit button
     * By default, isView=false
     * @returns {boolean}
     */
    activateActionButton() {
      return this.isView;
    },
    /**
     * hide the submit button depending on page view
     * By default isView=false
     * @returns {boolean}
     */
    hideActionButton() {
      return !this.isView;
    },
    /**
     * Return the requisition item
     * @returns {string}
     */
    routeParams() {
      return this.$route.query.code;
    },
    /**
     * Check if the field if for purchase Requisition
     * if true, show the vendor fields on view and submit
     * @returns {boolean}
     */
    isPurchaseRequest() {
      this.checkRequisitionType(this.documentType, this.requisitionType);
      return (
        this.requisitionType.isPurchaseRequisition ||
        this.resolveRequisitionType(this.requisitionType.purchaseRequisition)
      );
    },
    isStoreRequest() {
      this.checkRequisitionType(this.documentType, this.requisitionType);
      return (
        this.requisitionType.isStoreRequisition ||
        this.resolveRequisitionType(this.requisitionType.storeRequisition)
      );
    },
    dimensionValues() {
      return this.$store.getters["Requisition/reqGetters"]("dimensions");
    },
    filteredDimensionValues() {
      return this.dimensionValues.filter((dimension) => {
        return (
          dimension.code === this.formData.shortcutDimension1Code ||
          dimension.code === this.formData.shortcutDimension2Code ||
          dimension.code === this.formData.shortcutDimension3Code ||
          dimension.code === this.formData.shortcutDimension4Code
        );
      });
    },
  },
  methods: {
    CheckBudget: function () {
      this.$store.dispatch("Requisition/CheckBudget", {
        documentNo: this.formData.no,
        documentType: this.formData.documentType,
      });
    },
    removeBase64(item) {
      const itemIndex = this.attachmentBase64.findIndex(
        (el) => el.fileName === item
      );
      const itemBlobIndex = this.attachmentBase64.findIndex(
        (file) => file.name === item
      );
      this.attachmentBase64.splice(itemIndex, 1);
      this.attachmentBlobs.splice(itemBlobIndex, 1);
    },
    /**
     * Add a new line to the requisition request
     */
    saveRequisition() {
      if (this.isView) {
        delete this.formData.amount;
        delete this.formData.amountLCY;
        delete this.formData.amountIncludingVAT;
        delete this.formData.vatAmount;
        Array.prototype.push.apply(
          this.formData.attachments,
          this.attachmentBase64
        );
        this.$store.dispatch("Requisition/updateRequisition", {
          ...this.formData,
        });
      } else {
        if (this.$refs.requisitionForm.validate() && !this.isView) {
          if (this.formData.requisitionLines.length > 0) {
            this.formData.documentType = this.documentType;
            this.formData.requestedBy = this.userData.is_navuser
              ? this.userData.username
              : this.employee.number;
            delete this.formData.no;
            delete this.formData.headerNo;
            this.formData.attachments = this.attachmentBase64;
            console.log(this.formData);
            this.$store.dispatch("Requisition/saveRequisition", {
              ...this.formData,
            });
          } else {
            Event.$emit("ApiError", "Please select at least one billing item");
          }
        }
      }
    },
    /**
     * This method reset the document type
     * @param documentType
     * @returns {boolean}
     */
    resolveRequisitionType(documentType) {
      return (
        this.requisition.documentType === documentType ||
        this.documentType === documentType
      );
    },
    /**
     * This method resolves the status of the requisition on this view
     * @param status
     * @returns {boolean}
     */
    resolveRequisitionStatus(status) {
      return this.requisition.status === status;
    },
    /**
     * A method to open attachment dialog
     * @param val
     */
    openDialog(val) {
      this.imgDialog = true;
      this.base64 = val;
    },
    /**
     * A method to close attachment dialog
     * @param val
     */
    closeDialog: function (val) {
      this.imgDialog = val;
    },
    truncate(str) {
      return str.length > 30 ? str.substr(0, 10 - 1) + "..." : str;
    },
  },
  watch: {
    requisition: {
      handler: function (n, o) {
        if (this.$route.query.code) {
          this.formData = { ...this.requisition };
          delete this.formData.documentDate;
          const blob = [];

          this.formData.attachments !== undefined
            ? this.formData.attachments.map((el) => {
                this.getFileBlob(el.attachment, el.fileName).then((res) => {
                  blob.push(res);
                });
              })
            : [];
          this.attachmentBlobs = blob;
        }
      },
    },
    attachmentBlobs: {
      handler(n, o) {
        if (n.length !== 0) {
          const base64File = [];
          for (const file of n) {
            Promise.all([helpers.toBase64(file)]).then((res) => {
              res = res[0].split(",")[1];
              if (
                this.attachmentBase64.findIndex(
                  (el) => el.fileName === file.name
                ) === -1
              ) {
                base64File.push({ attachment: res, fileName: file.name });
              }
            });
          }
          this.attachmentBase64 = base64File;
        } else {
          this.attachmentBase64 = [];
        }
      },
    },
  },
};
</script>
<style scoped>
@import "../styles/style.css";
</style>
