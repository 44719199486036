<template>
  <v-toolbar flat dense>
    <v-spacer />
    <div class="pa-2 d-flex justify-end">
      <!--BEGIN of Reopen button action-->
      <div
        class="mx-2"
        v-if="activateActionButton && resolveRequisitionStatus('Rejected')"
      >
        <v-btn
          color="primary"
          class="button-actions"
          @click="reOpenRequisition()"
        >
          <span class="text-white">Reopen Requisition</span>
        </v-btn>
      </div>
      <!--END of Reopen button action-->
      <!--BEGIN send for delete button action-->
      <div
        class="mx-2"
        v-if="activateActionButton && resolveRequisitionStatus('Open')"
      >
        <v-btn
          color="error"
          class="button-actions"
          @click="deleteRequisition(requisition)"
        >
          <span class="text-white">Delete</span>
        </v-btn>
      </div>
      <!--END send for delete button action-->
      <!--BEGIN send for approval button action-->
      <div
        class="mx-2"
        v-if="activateActionButton && resolveRequisitionStatus('Open')"
      >
        <v-btn color="teal" class="button-actions" @click="sendApprovalRequest">
          <span class="text-white">Send For Approval</span>
        </v-btn>
      </div>
      <!--END send for approval button action-->
      <div
        class="mx-2"
        v-if="
          activateActionButton &&
          resolveRequisitionStatus('Open') &&
          displayBudget
        "
      >
        <v-btn
          @click="CheckBudget"
          color="teal"
          class="white--text mr-2"
          depressed
        >
          Check Budget
        </v-btn>
      </div>
      <!--END of Reopen button action-->
      <!--BEGIN of Cancel button action-->
      <div
        class="mx-2"
        v-if="
          activateActionButton && resolveRequisitionStatus('Pending Approval')
        "
      >
        <v-btn color="error" class="button-actions" @click="cancelRequisition">
          <span class="text-white">Cancel for approval </span>
        </v-btn>
      </div>
      <!--END of Cancel button action-->
      <!--BEGIN edit button action-->
      <div
        class="mx-2"
        v-if="activateActionButton && resolveRequisitionStatus('Open')"
      >
        <v-btn color="primary" class="button-actions" @click="saveRequisition">
          Save Changes
        </v-btn>
      </div>
      <!--END edit button action-->
      <div class="ml-2" v-if="hideActionButton">
        <v-btn color="primary" class="button-actions" @click="saveRequisition">
          {{ saveButtonCaption }}
        </v-btn>
      </div>
      <!--END submit button action-->
      <!--BEGIN print button action-->
      <div class="mx-2" v-if="activateActionButton && displayBudget">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              class="button-actions white--text"
              v-on="on"
              v-bind="attrs"
              :to="{
                name: 'ReqDocument',
                params: { headerNo: requisition.no },
              }"
            >
              Report
            </v-btn>
          </template>
          <span>view report</span>
        </v-tooltip>
      </div>
      <!--END print button action-->
    </div>
  </v-toolbar>
</template>
<script>
export default {
  name: "RequestCardActions",
  props: {
    requisition: {
      type: Object,
      required: true,
    },
    activateActionButton: {
      type: Boolean,
      default: true,
    },
    hideActionButton: {
      type: Boolean,
      default: false,
    },
    saveButtonCaption: {
      type: String,
      default: "Save",
    },
    imprestActionsPosition: {
      type: String,
      default: "top",
    },
    displayBudget: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    resolveRequisitionStatus(status) {
      return this.requisition.status === status;
    },
    saveRequisition() {
      this.$emit("saveRequisition");
    },
    sendApprovalRequest(method) {
      this.$emit("sendApprovalRequest", method);
    },
    deleteRequisition(requisition) {
      this.$emit("deleteRequisition", requisition);
    },
    cancelRequisition() {
      this.$emit("cancelRequisition");
    },
    CheckBudget() {
      this.$emit("CheckBudget");
    },
    reOpenRequisition() {
      this.$emit("reOpenRequisition");
    },
  },
};
</script>
